import {
  Admin,
  Resource,
  ListGuesser,
  ShowGuesser,
  EditGuesser,
  UserMenu,
  CustomRoutes,
} from 'react-admin';
import 'react-toastify/dist/ReactToastify.css';
// import "bootstrap/dist/css/bootstrap.min.css";
import { ToastContainer } from 'react-toastify';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';

import dataProvider, { authProvider } from './drfClient';
// const dataProvider = jsonServerProvider("https://jsonplaceholder.typicode.com");
import Dashboard from './pages/Dashboard';
import SignIn from './pages/Signin';
import LoginPage from './pages/LoginPage';
import { MyLayout } from './components/Layout';
import {
  CreateStripeProduct,
  ShowStripeProduct,
  ListStripeProduct,
} from './pages/Stripe/Products';
import { ShowUser } from './pages/Users/ShowUser';
import { CreateCustomer, ShowCustomer, ListCustomer } from './pages/Customer';
import { CreateOrganisation } from './pages/Organisation/CreateOrganisation';
import { CreatePlan } from './pages/Subscription/CreatePlan';
import Login from './pages/Onboarding/Login/index';
import Sentry from './pages/Onboarding/Login/sentry';
import OtpScreen from './pages/Onboarding/OTP/index';
import SignUp from './pages/Onboarding/SignUp/index';
import { ListPlan } from './pages/Subscription/ListPlan';
import { ListTenant } from './pages/Tenants/ListTenant';
import { ShowTenant } from './pages/Tenants/ShowTenant';
import { ListFeature } from './pages/Features/ListFeature';
import { CreateFeature } from './pages/Features/CreateFeature';
import { EditPlan } from './pages/Subscription/EditPlan';
import { CreateCustomPlan } from './pages/CustomSubscription/CreateCustomPlan';
import { ListCustomPlan } from './pages/CustomSubscription/ListCustomPlan';
import { EditFeature } from './pages/Features/EditFeature';
import { ShowPlan } from './pages/Subscription/ShowPlan';
import { ShowCustomPlan } from './pages/CustomSubscription/ShowCustomPlan';
import { EditCustomPlan } from './pages/CustomSubscription/EditCustomPlan';
import { ListActivePlan } from './pages/ActiveSubscription/ListActivePlan';
import { ShowActivePlan } from './pages/ActiveSubscription/ShowActivePlan';
import { EditActivePlan } from './pages/ActiveSubscription/EditActivePlan';
import Layout from './pages/Layout';
import AuthenticateLayout from './pages/AuthenticateLayout';
import Organisation from './pages/Onboarding/OrganisationDetails';
import ForgotPassword from './pages/Onboarding/ForgotPassword';
import ReleaseList, { ListTracker } from './pages/Tracker Application/ListTracker';
import CreateCoupon from './pages/Coupon/CreateCoupom';
import CouponList from './pages/Coupon/CouponList';
import CouponShow from './pages/Coupon/ShowCoupon';
import EditCoupon from './pages/Coupon/EditCoupon';

const App = () => (
  <>
    <BrowserRouter>
      <Routes>
        <Route
          basename="/"
          path="/app/login"
          element={<AuthenticateLayout component={<Login />} isLoginScreen={true}/>}
        />
        <Route
          path="/app/login/sentry-debug"
          element={<Layout component={<Sentry />} />}
        />
        <Route path="/app/signup" element={<AuthenticateLayout component={<SignUp />} />} />
        <Route
          path="/app/signup/verify-email"
          element={<Layout component={<OtpScreen />} />}
        />{' '}
        {/* 👈 Renders at /#/app/ */}
        <Route
          path="/app/signup/organisation-detail"
          element={<Layout component={<Organisation />} />}
        />{' '}
        {/* 👈 Renders at /#/app/ */}
        <Route
          path="/app/forgotpassword"
          element={<Layout component={<ForgotPassword />} />}
        />
        <Route
          path="/dashboard*"
          basename="/dashboard"
          element={
            <Admin
              dashboard={Dashboard}
              authProvider={authProvider}
              dataProvider={dataProvider}
              layout={MyLayout}
              basename="/dashboard"
              loginPage={LoginPage}
            >
              <Resource
                name="users"
                list={ListGuesser}
                show={ShowUser}
                edit={EditGuesser}
              />
              <Resource
                name="customer"
                list={ListCustomer}
                show={ShowCustomer}
                edit={EditGuesser}
                create={CreateCustomer}
              />
              <Resource
                name="organisation"
                list={ListGuesser}
                show={ShowGuesser}
                edit={EditGuesser}
                create={CreateOrganisation}
              />
              <Resource
                name="features"
                list={ListFeature}
                show={ShowGuesser}
                edit={EditFeature}
                create={CreateFeature}
              />
              <Resource
                name="subscription"
                list={ListPlan}
                show={ShowPlan}
                edit={EditPlan}
                create={CreatePlan}
              />
              <Resource
                name="active-subscription"
                list={ListActivePlan}
                show={ShowActivePlan}
                edit={EditActivePlan}
                // create={CreatePlan}
              />
              {/* <Resource
        name="custom-subscription"
        list={ListCustomPlan}
        show={ShowCustomPlan}
        edit={EditCustomPlan}
        create={CreateCustomPlan}
      /> */}

              <Resource
                name="tenants"
                list={ListTenant}
                show={ShowTenant}
                edit={EditGuesser}
                create={CreateOrganisation}
              />

              <Resource
                name="payments/product"
                list={ListStripeProduct}
                show={ShowStripeProduct}
                edit={EditGuesser}
                create={CreateStripeProduct}
                options={{ label: 'Stripe Products' }}
              />
              <Resource
                name="payments/checkout"
                list={ListGuesser}
                show={ShowUser}
                edit={EditGuesser}
                options={{ label: 'Stripe Checkout Session' }}
              />
              <Resource
                name="payments/price"
                list={ListGuesser}
                show={ShowGuesser}
                edit={EditGuesser}
                options={{ label: 'Stripe Prices' }}
              />
              <Resource
                name="coupon"
                list={CouponList}
                show={CouponShow}
                edit={EditCoupon }
                create={CreateCoupon}
                options={{ label: 'Coupon' }}
              />
              <Resource
                name="tracker-releases"
                list={ReleaseList}
                show={ShowGuesser}
                edit={EditGuesser}
                options={{ label: 'Tracker Application' }}
              />
            </Admin>
          }
        />
      </Routes>
      <CookiesProvider></CookiesProvider>
    </BrowserRouter>
    <ToastContainer position="top-right" autoClose={5000} />
  </>
);

export default App;
