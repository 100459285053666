import * as React from 'react';
import { useState } from 'react';
import { useLogin, useNotify, Notification } from 'react-admin';
import { Box, Card, CardContent, TextField, Button, Typography, Container, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

const LoginPage = ({ theme }) => {
    const [username, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [step, setStep] = useState(1);
    const [devices, setDevices] = useState([]);
    const [selectedDevice, setSelectedDevice] = useState('');
    const [challengeCode, setChallengeCode] = useState('');
    const [tempToken, setTempToken] = useState(null);
    const login = useLogin();
    const notify = useNotify();

    const handleInitialLogin = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('/api/auth/auth_token/', {
                method: 'POST',
                body: JSON.stringify({ username, password }),
                headers: { 'Content-Type': 'application/json' },
            });
            const data = await response.json();
            
            if (data.requiresMfa) {
                setTempToken(data.tempToken);
                // Fetch available MFA devices
                const devicesResponse = await fetch('/api/auth/devices', {
                    headers: { 'Authorization': `Bearer ${data.tempToken}` }
                });
                const devicesData = await devicesResponse.json();
                setDevices(devicesData);
                setStep(2);
            } else {
                // Direct login if MFA not required
                login({password: data.token,username});
            }
        } catch (error) {
            notify('Invalid email or password', { type: 'error' });
        }
    };

    const handleDeviceSelection = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('/api/auth/generate_challenge/', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${tempToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ deviceId: selectedDevice }),
            });
            if (response.ok) {
                setStep(3);
            }
        } catch (error) {
            notify('Failed to generate challenge', { type: 'error' });
        }
    };

    const handleChallengeSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('/api/auth/verify/', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${tempToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    deviceId: selectedDevice,
                    challengeCode,
                }),
            });
            const data = await response.json();
            if (response.ok) {
                login({password: data.token,username});
            }
        } catch (error) {
            notify('Invalid verification code', { type: 'error' });
        }
    };

    const renderStep = () => {
        switch (step) {
            case 1:
                return (
                    <Box component="form" onSubmit={handleInitialLogin} sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="username"
                            label="Email Address/ Username"
                            name="username"
                            autoComplete="email"
                            autoFocus
                            value={username}
                            onChange={e => setEmail(e.target.value)}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Continue
                        </Button>
                    </Box>
                );
            case 2:
                return (
                    <Box component="form" onSubmit={handleDeviceSelection} sx={{ mt: 1 }}>
                        <FormControl fullWidth margin="normal">
                            <InputLabel>Select Authentication Device</InputLabel>
                            <Select
                                value={selectedDevice}
                                onChange={(e) => setSelectedDevice(e.target.value)}
                                required
                            >
                                {devices.map((device) => (
                                    <MenuItem key={device.id} value={device.id}>
                                        {device.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Send Code
                        </Button>
                    </Box>
                );
            case 3:
                return (
                    <Box component="form" onSubmit={handleChallengeSubmit} sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="challengeCode"
                            label="Enter Verification Code"
                            name="challengeCode"
                            autoFocus
                            value={challengeCode}
                            onChange={(e) => setChallengeCode(e.target.value)}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Verify
                        </Button>
                    </Box>
                );
            default:
                return null;
        }
    };

    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Box sx={{ 
                    m: 1,
                    bgcolor: 'primary.main',
                    p: 2,
                    borderRadius: '50%',
                    display: 'flex',
                    justifyContent: 'center'
                }}>
                    <LockOutlinedIcon sx={{ color: 'white' }} />
                </Box>
                <Typography component="h1" variant="h5" sx={{ mt: 2 }}>
                    Sign In
                </Typography>
                <Card sx={{ mt: 3, width: '100%' }}>
                    <CardContent>
                        {renderStep()}
                    </CardContent>
                </Card>
            </Box>
            <Notification />
        </Container>
    );
};

export default LoginPage;